import { Box, Heading, Image, Flex } from "@chakra-ui/core"
import React from "reactn"
import { HtmlBox } from "../../components/HtmlBox"
import { Json } from "../../components/Json"
import { ShowInfos } from "./ShowInfos"
import ButtonUpdateShow from "../../components/ButtonUpdateShow"

const ShowDetails_ = ({ show, refresh }) => (
  <Box m="3" p="5" borderWidth="1px" rounded="lg" gridArea="ShowDetails">
    {show.image && (
      <Image
        float="right"
        alt={show.name}
        src={show?.image?.original?.replace("http://", "https://")}
        w="40%"
        maxW="500px"
        rounded="lg"
        fallbackSrc={`https://placehold.co/374x540?text=${show?.name?.replace(" ", "+")}`}
      />
    )}
    <Heading fontWeight="semibold" as="h1">
      {show.name}
    </Heading>
    <HtmlBox
      maxW={{ md: "50%", base: "100%" }}
      my="3"
      p="2"
      borderLeftWidth="5px"
      borderLeftColor="red.200"
    >
      {show.summary}
    </HtmlBox>
    <ShowInfos show={show} />
    <Flex
      maxW={{ md: "50%", base: "100%" }}
      borderWidth="1px"
      borderColor="white"
      rounded="lg"
      alignItems="center"
      my={3}
      p={5}
    >
      <ButtonUpdateShow id={show?.id} after={refresh} />
    </Flex>
    <div style={{ clear: "both" }} />
    <Json data={show} visible={false} />
  </Box>
)
export const ShowDetails = React.memo(ShowDetails_)
