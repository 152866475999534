import React from "reactn"
import * as moment from "moment"
import { SelectShows } from "./CurrentShows"

function NextShows() {
  const yesterday = moment().tz("America/New_York").subtract(1, "day")
  // console.log("yesterday", yesterday.format("LLLL"))
  // return "hello next shows"
  return <SelectShows episodeSearch={[["airstamp", ">", new Date(yesterday)]]} />
}
export default NextShows
