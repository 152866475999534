import React, { useState } from "reactn"
import { Box, Button, Heading, Stack, Grid, Image } from "@chakra-ui/core"
import { Formik, Form } from "formik"
import CustomField from "../components/CustomField"
import { ShowInfos } from "./Show/ShowInfos"
import { HtmlBox } from "../components/HtmlBox"
import ButtonUpdateShow from "../components/ButtonUpdateShow"

export default () => {
  const [series, setSeries] = useState([])
  return (
    <Box>
      <Heading textAlign="center" m={10}>
        Ajouter une série
      </Heading>
      <Formik
        initialValues={{ search: "" }}
        onSubmit={(values, actions) => {
          console.log("add show", values, actions)
          const url = `https://api.tvmaze.com/search/shows?q=`
          setSeries([])
          fetch(url + values.search)
            .then((res) => res.json())
            .then((res) => console.log("res: ", res) || setSeries(res))
        }}
      >
        <Box as={Form} display="flex" justifyContent="center" alignItems="center">
          <CustomField name="search" mx={5} useLabel={false} label="Série" />
          <Button type="submit" variantColor="red" marginLeft={5}>
            Rechercher
          </Button>
        </Box>
      </Formik>
      <Stack>
        {series.map((s) => (
          <Grid
            key={s.show.id}
            margin="30px auto"
            w={{ base: "95vw", md: "70vw" }}
            templateColumns={{ md: "30% 70%", base: "100%" }}
            templateAreas={{
              md: '"title title" "summary summary" "image infos" ',
              base: '"title" "image" "summary" "infos"',
            }}
          >
            <Box gridArea="title">
              <Heading>{s?.show?.name}</Heading>
              <ButtonUpdateShow add id={s?.show?.id} />
            </Box>
            <Image gridArea="image" src={s?.show?.image?.medium?.replace("http://", "https://")} />
            <ShowInfos show={s?.show} gridArea="infos" w="full" />
            <HtmlBox
              maxW={{ base: "100%" }}
              my="3"
              p="2"
              borderLeftWidth="5px"
              borderLeftColor="red.200"
              gridArea="summary"
            >
              {s?.show?.summary}
            </HtmlBox>
          </Grid>
        ))}
      </Stack>
    </Box>
  )
}
