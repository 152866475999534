import {
  Box,
  DarkMode,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  PseudoBox,
  Text,
  Tooltip,
} from "@chakra-ui/core"
import partition from "lodash/partition"
import { Link as ReactLink } from "react-router-dom"
import React from "reactn"

const Person = ({ person, showId }) => {
  const [[current = {}], others] = partition(
    Object.values(person?.caracters),
    (p) => p?.show?.id === Number(showId)
  )
  //   console.log("cast: ", person?.caracters, current, others)
  return (
    <Popover usePortal closeOnBlur={true}>
      <PopoverTrigger>
        <PseudoBox
          _hover={{ opacity: "0.5" }}
          position="relative"
          borderWidth="0px"
          m="0"
          cursor="pointer"
          minH="100px"
          w="100%"
        >
          <Image
            src={current?.image?.medium?.replace("http://", "https://")}
            fallbackSrc={`https://placehold.co/210x295?text=Chargement...`}
          />
          {others.length > 0 && (
            <Box position="absolute" top="-10px" right="-10px">
              <Tooltip
                label="Cet acteur a joué dans une autre de mes séries favorites"
                // placement="top"
                bg="red.500"
                color="white"
                shouldWrapChildren
              >
                {/* <IconButton
              icon="bell"
              size="md"
              isRound="true"
              variant="solid"
              variantColor="red"
            /> */}
                <Icon name="bell" size="24px" color="red.500" />
              </Tooltip>
            </Box>
          )}
          <Box
            position="absolute"
            bottom="0"
            right="0"
            left="0"
            // opacity="0.5"
            background="linear-gradient(#4747470d,#ffffffdb)"
            color="black"
            fontWeight="bolder"
            textShadow="0px 0px 7px #cfc2c2"
            p="1"
          >
            <Text fontSize="sm">{person?.name}</Text>
            <Text fontSize="xs">as {current?.name}</Text>
          </Box>
        </PseudoBox>
      </PopoverTrigger>
      <DarkMode>
        <PopoverContent
          border="0"
          zIndex={4}
          color="white"
          minWidth={{ base: "90vw", md: "60vw" }}
          p="2"
        >
          <PopoverCloseButton />
          <Heading as="h4">{person?.name}</Heading>
          <Flex p={2}>
            {person?.image && (
              <Image
                w={{ base: "30%", md: "40%" }}
                objectFit="cover"
                src={person?.image?.medium?.replace("http://", "https://")}
                alt=""
                alignSelf="baseline"
                rounded="lg"
                fallbackSrc={`https://placehold.co/210x295?text=Chargement...`}
              />
            )}
            <Box flexGrow="1" ml="2">
              <Box borderWidth="1px" p="1" rounded="lg" borderColor="white">
                gender: <b>{person?.gender}</b>
                <br />
                Pays: <b>{person?.country?.name}</b>
                <br />
                Date de naissance: <b>{person?.birthday?.split(/-/).reverse().join("/")}</b>
                <br />
                {person?.deathday && (
                  <>
                    Date de mort: <b>{person?.deathday?.split(/-/).reverse().join("/")}</b>
                    <br />
                  </>
                )}
              </Box>
              {others.length > 0 && (
                <Box borderWidth="1px" mt="1" p="1" rounded="lg" borderColor="white">
                  {others?.map((o) => (
                    <Flex key={o.id} alignItems="flex-start">
                      <Box flexGrow="1">
                        <Text fontSize={{ base: "sm", md: "lg" }}>
                          <ReactLink to={`/show/${o?.show?.id}`}>{o?.show?.name}</ReactLink>
                        </Text>
                        <Text fontSize={{ base: "xs", md: "md" }}>as {o?.name}</Text>
                      </Box>
                      <Image
                        w={{ md: "100px", base: "80px" }}
                        src={o?.image?.medium?.replace("http://", "https://")}
                        alt=""
                      />
                    </Flex>
                  ))}
                </Box>
              )}
              <Flex justifyContent="space-around">
                <Link
                  target="_blank"
                  href={`https://www.google.com/search?q=${person?.name.split(/\s+/).join("+")}`}
                >
                  Google
                </Link>
                <Link target="_blank" href={person?.url}>
                  Plus d'infos
                </Link>
              </Flex>
            </Box>
          </Flex>
        </PopoverContent>
      </DarkMode>
    </Popover>
  )
}
export default React.memo(
  Person,
  (prevProps, nextProps) => prevProps.person.id === nextProps.person.id
)
