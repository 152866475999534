import {
  Box,
  Button,
  Stack,
  Heading,
  Image,
  Text,
  Flex,
  PseudoBox,
  Switch,
  FormLabel,
} from "@chakra-ui/core"
import React, { useGlobal } from "reactn"
import { Json } from "../components/Json"
import { Loading } from "../components/Loading"
import { useCollection } from "../services/firebase"
import * as moment from "moment"
import "moment/locale/fr"
import "moment-timezone"
import groupBy from "lodash/groupBy"
import { ErrorMsg } from "../components/ErrorMsg"
import { HtmlBox } from "../components/HtmlBox"
import { Link } from "react-router-dom"
import padStart from "lodash/padStart"
import { BoxWithTitle } from "../components/BoxWithTitle"

const EpisodeNumber = ({ e }) => (
  <>
    S{padStart(e.season, 2, "0")}E{padStart(e?.number, 2, "0")}
  </>
)

const FullView = React.memo(({ elist, now }) =>
  Object.entries(elist).map(([group, eps]) => (
    <Stack key={group} spacing={2}>
      <BoxWithTitle
        title={moment(group).format("dddd D MMMM Y")}
        titleBg={moment(eps[0]?.airstamp?.seconds * 1000) < now ? "red.500" : "red.300"}
      >
        {eps.map((e) => (
          <Link key={e.id} to={`/show/${e?.show?.id}`}>
            <Box
              display="flex"
              m="2"
              alignItems="flex-start"
              flexDirection={{ base: "column", md: "row" }}
            >
              <Image
                rounded="lg"
                src={e?.image?.medium?.replace("http://", "https://")}
                fallbackSrc={`https://placehold.co/250x140?text=Image+non+disponible...`}
              />
              <Box ml="2" flexGrow="1">
                <Text fontSize="2xl" fontWeight="bold" color="red.300">
                  {e?.airtime && `${e?.airtime}: `}
                  {e?.show?.name}
                </Text>
                <Text fontSize="md" fontWeight="semibold">
                  <EpisodeNumber e={e} />: {e?.name}
                </Text>
                <HtmlBox my="3" p="2" borderLeftWidth="5px" borderLeftColor="red.200">
                  {e?.summary}
                </HtmlBox>
              </Box>
            </Box>
          </Link>
        ))}
      </BoxWithTitle>
    </Stack>
  ))
)

const SmallView = React.memo(({ elist, now }) =>
  Object.entries(elist).map(([group, eps]) => {
    const rowColor = moment(eps[0]?.airstamp?.seconds * 1000) < now ? "red.500" : "red.400"
    return (
      <Stack key={group} spacing={0}>
        <Box
          p={2}
          m="10px 0 0 0"
          rounded="lg"
          fontWeight="bold"
          textTransform="capitalize"
          fontSize="xl"
          bg={rowColor}
        >
          {moment(group).format("dddd D MMMM Y")}
        </Box>
        {eps.map((e) => (
          <PseudoBox
            key={e.id}
            as={Link}
            to={`/show/${e?.show?.id}`}
            display="flex"
            flexWrap="wrap"
            marginLeft={{ base: "10px", md: "50px" }}
            marginTop={{ base: "10px", md: "0px" }}
            _hover={{ bg: "#474646" }}
            borderColor={rowColor}
            borderLeftWidth="6px"
            borderTopWidth={{ base: "1px", md: "0" }}
            roundedTopLeft={{ base: "md", md: "none" }}
            py={1}
            borderTopRightRadius={{ base: "30px", md: "0" }}
            borderBottomLeftRadius={{ base: "30px", md: "0" }}
          >
            <Box w={{ base: "100%", md: "40%" }} paddingLeft="20px" fontWeight="bold">
              {e?.show?.name}
            </Box>
            <Box w={{ base: "30%", md: "20%" }} textAlign="right" paddingRight="15px">
              <EpisodeNumber e={e} />
            </Box>
            <Box w={{ base: "70%", md: "40%" }}>{e?.name}</Box>
          </PseudoBox>
        ))}
      </Stack>
    )
  })
)

export function SelectShows({ episodeSearch }) {
  //   const date = moment()
  const [showFull, setShowFull] = useGlobal("showFull")
  if (showFull === undefined) setShowFull(false)
  moment.locale("fr")
  const now = moment()
  const [episodes, errorEpisodes, refresh] = useCollection("episodes", {
    where: episodeSearch,
    // orderBy: [["name"]],
  })

  if (errorEpisodes) return <ErrorMsg msg={errorEpisodes} />
  if (!episodes) return <Loading />
  const elist = groupBy(episodes, "airdate")

  return (
    <Box>
      <Box m="3" display="flex" justifyContent="center">
        <Button leftIcon="repeat" onClick={refresh} variantColor="facebook" color="white">
          Mettre à jour
        </Button>
        <Flex
          justify="center"
          align="center"
          borderColor="white"
          border="1px"
          rounded="lg"
          mx="10px"
          px="10px"
        >
          <FormLabel htmlFor="showFull">Format Long</FormLabel>
          <Switch id="showFull" isChecked={showFull} onChange={(e) => setShowFull(!showFull)} />
        </Flex>
      </Box>
      <Box p="5">
        {showFull ? <FullView elist={elist} now={now} /> : <SmallView elist={elist} now={now} />}
      </Box>
      <Json data={episodes} />
    </Box>
  )
}

function CurrentShows() {
  const lastMonday = moment()
    .tz("America/New_York")
    .subtract(1, "week")
    .startOf("day")
    .startOf("isoWeek")
  //   const lastMonday = moment()
  const nextSunday = moment().tz("America/New_York").add(1, "week").endOf("day").endOf("isoWeek")
  //   console.log("last monday: ", lastMonday.format("LLLL"), nextSunday.format("LLLL"))
  return (
    <SelectShows
      episodeSearch={[
        ["airstamp", ">", new Date(lastMonday)],
        ["airstamp", "<=", new Date(nextSunday)],
      ]}
    />
  )
}
export default CurrentShows
